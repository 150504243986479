<template>
  <el-dialog
    title="提示"
    top="10vh"
    width="870px"
    append-to-body
    custom-class="mail-dialog"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    @close="close">
    <div slot="title">新增寄件{{ deptType === 'ZY' ? '（直营）' : deptType === 'JM' ? '（加盟）' : '' }}</div>
    <section class="section-box">
      <div class="mail-box row space-between">
        <div class="mail-box-item">
          <div
            class="row align-center item-top"
            style="background: linear-gradient(to right, rgb(248, 240, 255), rgb(231, 248, 255), rgb(250, 254, 255))">
            <span class="label">寄</span>
            <span>寄件人</span>
          </div>
          <div class="item-form">
            <el-form inline size="small" label-width="80px">
              <el-form-item label="类型" required>
                <el-select disabled placeholder="请选择" v-model="send.senderType" style="width: 260px">
                  <el-option v-for="item in receiveTypeList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名" required>
                <el-input placeholder="请输入" style="width: 260px" v-model="send.senderName"></el-input>
              </el-form-item>
              <el-form-item label="手机号" required>
                <el-input placeholder="请输入" style="width: 260px" v-model="send.senderMobile"></el-input>
              </el-form-item>
              <el-form-item label="省市区" required>
                <el-input
                  disabled
                  placeholder="请选择"
                  style="width: 260px"
                  :value="send.senderProvince + send.senderCity + send.senderArea"></el-input>
              </el-form-item>
              <el-form-item label="详细地址" required>
                <el-input
                  disabled
                  placeholder="请选择"
                  v-model="send.senderAddress"
                  type="textarea"
                  style="width: 260px"
                  resize="none"
                  :rows="3"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="mail-box-item">
          <div
            class="row align-center space-between item-top"
            style="background: linear-gradient(to right, rgb(255, 241, 240), rgb(249, 253, 255), rgb(250, 250, 255))">
            <div class="row align-center">
              <span class="label">收</span>
              <span>收件人<template v-if="deptType === 'ZY'">(其他收件方请联系后台人员)</template></span>
            </div>
            <span v-if="deptType === 'JM'" style="color: #0b83f3; cursor: pointer" @click="addressParams = true"
              ><i class="el-icon-edit"></i> 智能填写</span
            >
          </div>
          <div class="item-form">
            <el-form inline size="small" label-width="80px">
              <el-form-item label="类型" required>
                <el-select
                  v-if="deptType === 'ZY'"
                  placeholder="请选择"
                  v-model="receive.receiveType"
                  :style="{ width: receive.receiveType == '3' && deptType === 'ZY' ? '150px' : '260px' }"
                  @change="storeChange(receive.receiveType)"
                  style="width: 260px">
                  <el-option v-for="item in receiveTypeList2" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-button
                  v-if="receive.receiveType == '3' && deptType === 'ZY'"
                  @click="checkStore"
                  style="background: #1590ff; color: #fff; width: 100px; border: 1px solid #1590ff; margin-left: 10px">
                  选择店铺
                </el-button>
                <div v-if="deptType === 'ZY'" style="line-height: 20px">根据所选类型将自动填入对应的收件信息</div>
                <el-select v-else disabled placeholder="请选择" v-model="receive.receiveType" style="width: 260px">
                  <el-option v-for="item in receiveTypeList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <div
                style="height: 30px; margin-top: -10px; margin-left: 80px"
                v-if="this.storeList && this.storeList.length && receive.receiveType == '3' && deptType === 'ZY'">
                寄件对象：{{ this.storeList[0].name }}
              </div>
              <el-form-item label="姓名" required>
                <el-input placeholder="请输入" v-model="receive.receiveName" style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="手机号" required>
                <el-input placeholder="请输入" v-model="receive.receiveMobile" style="width: 260px"></el-input>
              </el-form-item>
              <el-form-item label="省市区" required>
                <el-input
                  disabled
                  v-if="deptType === 'ZY'"
                  placeholder="请选择"
                  :value="receive.receiveProvince + receive.receiveCity + receive.receiveArea"
                  style="width: 260px"></el-input>
                <el-cascader
                  v-else
                  placeholder="请选择"
                  style="width: 260px"
                  v-model="receive.addressInfo"
                  :props="{ label: 'name', children: 'nodes', value: 'name' }"
                  :options="addressArray"
                  @change="getSelectAddress">
                </el-cascader>
              </el-form-item>
              <el-form-item label="详细地址" required>
                <el-input
                  placeholder="请输入"
                  v-model="receive.receiveAddress"
                  type="textarea"
                  style="width: 260px"
                  resize="none"
                  :rows="deptType === 'ZY' ? 2 : 3"
                  :disabled="deptType === 'ZY'">
                </el-input>
              </el-form-item>
              <!-- <div
                v-if="deptType === 'ZY' && receive.receiveType !== '3'"
                style="margin-left: 80px; color: red; margin-top: -15px; width: 260px">
                收件地址仅限公司，如需寄给其收件方，请联系后台下单
              </div> -->
            </el-form>
          </div>
        </div>
      </div>

      <div class="common-box">
        <div class="row">
          <div class="column  goods-logs">
          <div class="row align-center">
            <span><i>*</i>物品名称</span>
            <el-input v-model="common.description" placeholder="请输入" size="small" style="width: 300px"></el-input>
          </div>
          <div class="row align-center goods-logs auto-jd">
            <span><i>*</i>录入方式</span>
            <div>
              <el-radio v-model="common.isAuto" label="Y">{{deptType === 'ZY'?'京东快递自动下单':'京东自动下单'}}</el-radio>
            </div>
            <span v-if="deptType === 'ZY'" style="width: 150px;color:#a4a4a4">重货/快运请联系后台</span>
          </div>
        </div>
          <div style="margin-left: 20px;" v-if="deptType === 'ZY'">
            1、不允许通过该功能寄衣服、裤子、鞋履、配饰等货品；<br/>
            2、撤店时请联系后台人员下单，不允许通过该功能下单；<br/>
            3、盘点机/电脑/平板等贵重物品，请联系钉钉行政物料发放进行保价。<br/>
            违反以上规则需自己承相运费和相关损失！
          </div>
          <div style="margin-left: 20px" v-if="deptType !== 'ZY'">
            不允许通过该功能寄门店在售的衣服、裤子、鞋履、配饰等货品，否则需自己承担运费！
          </div>
        </div>
        <div class="row goods-logs">
          <span>备注</span>
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="common.remark"
            :rows="4"
            style="width: 90%"></el-input>
        </div>
      </div>

      <div class="row space-end action-box">
        <el-button class="el-button" size="small" @click="close">取消</el-button>
        <el-button class="el-button" size="small" type="primary" :loading="submitLoading" @click="submitBefore"
          >确认</el-button
        >
      </div>
    </section>
    <el-dialog
      append-to-body
      width="30%"
      title="智能填写"
      :visible.sync="addressParams"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="addressParams = false">
      <div style="margin-top: -20px"></div>
      <el-input
        type="textarea"
        placeholder="在此处粘贴整段地址，自动识别姓名，手机号码和地址例：张三，16478909988，广东省广州市番禺区明朗创意园"
        v-model="addressData"
        :rows="5">
      </el-input>
      <div slot="footer">
        <el-button size="small" type="primary" @click="onAddressInput">确认</el-button>
      </div>
    </el-dialog>
    <storeDialog ref="store" @confirm="confirm"></storeDialog>
  </el-dialog>
</template>

<script>
import storeDialog from '@/components/ErpSelector/storeDialog.vue'
import { queryDeptAddress } from '@/libs/http/modules/customer'
import { queryCityInfoList } from '@/libs/http/api'
import AddressParse from 'address-parse'
import { erpWaybillAdd } from '@/libs/http/modules/erp'

export default {
  components: {
    storeDialog
  },
  provide() {
    return {
      multiple: false,
      isConsole: true,
      modelKey: ''
    }
  },
  data() {
    return {
      storeList: [],
      dialogVisible: true,
      submitLoading: false,
      deptType: this.$cache.local.get('saleQuality'),
      addressParams: false,
      addressArray: [],
      addressData: '',
      receiveTypeList: [
        { label: '公司', value: '1' },
        { label: '其他', value: '2' },
        { label: '店铺', value: '3' }
      ],
      receiveTypeList2: [
        { label: '公司', value: '1' },
        { label: '店铺', value: '3' }
      ],
      send: {
        senderObject: this.$cache.local.get('marketingUnitId'),
        senderType: '3',
        senderMobile: '',
        senderName: '',
        senderProvince: '',
        senderCity: '',
        senderArea: '',
        senderAddress: ''
      },
      receive: {
        receiveType: '',
        receiveMobile: '',
        receiveName: '',
        receiveAddress: '',
        receiveProvince: '',
        receiveCity: '',
        receiveArea: '',
        addressInfo: ''
      },
      common: {
        description: '',
        remark: '',
        isAuto: 'Y',
        otherAddress: ''
      }
    }
  },
  created() {
    if (this.deptType === 'JM') {
      this.receive.receiveType = '2'
    }
    if (this.receive && this.receive.receiveType === '1') {
      this.receive.receiveType = ''
      this.receive.receiveProvince = '广东省'
      this.receive.receiveCity = '广州市'
      this.receive.receiveArea = '番禺区'
      this.receive.receiveAddress = '石壁街道创园路26号明朗创意园1楼'
    }
    this.getAddressData()
    this.queryAddress()
  },
  methods: {
    storeChange(req) {
      if (req !== '3') {
        this.receive.receiveMobile = ''
        this.receive.receiveName = ''
        this.receive.receiveProvince = '广东省'
        this.receive.receiveCity = '广州市'
        this.receive.receiveArea = '番禺区'
        this.receive.receiveAddress = '石壁街道创园路26号明朗创意园1楼'
      } else {
        if (this.storeList && this.storeList.length) {
          this.queryData()
        } else {
          this.receive.receiveProvince = ''
          this.receive.receiveCity = ''
          this.receive.receiveArea = ''
          this.receive.receiveAddress = ''
          this.receive.receiveMobile = ''
          this.receive.receiveName = ''
        }
      }
    },
    confirm(list) {
      this.storeList = list
      this.queryData()
    },
    queryData() {
      queryDeptAddress({
        id: this.storeList[0].protoData.deptId
      })
        .then(data => {
          if (data) {
            this.receive.receiveProvince = data.province
            this.receive.receiveCity = data.city
            this.receive.receiveArea = data.area
            this.receive.receiveAddress = data.address
            this.receive.receiveMobile = data.principalPhone
            this.receive.receiveName = data.principal
          }
        })
        .catch(err => {})
    },
    checkStore() {
      this.$nextTick(() => {
        this.$refs.store.show(this.storeList)
      })
    },
    close() {
      this.dialogVisible = false
      this.$emit('close')
    },
    queryAddress() {
      queryDeptAddress({
        id: this.$cache.local.get('deptId')
      })
        .then(data => {
          if (data) {
            this.send.senderProvince = data.province
            this.send.senderCity = data.city
            this.send.senderArea = data.area
            this.send.senderAddress = data.address
            this.send.senderName = data.principal
            this.send.senderMobile = data.principalPhone
          }
        })
        .catch(err => {})
    },
    getAddressData() {
      queryCityInfoList()
        .then(data => {
          this.addressArray = data.nodes
        })
        .catch(err => {})
    },
    getSelectAddress(e) {
      this.receive.receiveProvince = e[0]
      this.receive.receiveCity = e[1]
      this.receive.receiveArea = e[2]
    },
    onAddressInput() {
      if (!this.addressData) return
      const parse = AddressParse.parse(this.addressData)
      if (parse && parse.length > 0) {
        const content = parse[0]
        this.receive.receiveProvince = content.province
        this.receive.receiveCity = content.city
        this.receive.receiveArea = content.area
        this.receive.receiveName = content.name
        this.receive.receiveMobile = content.mobile
        this.receive.receiveAddress = content.details
        this.receive.addressInfo = [content.province, content.city, content.area]
        this.addressParams = false
      }
    },
    deleteProperty(obj, propList) {
      propList.forEach(key => {
        delete obj[key]
      })
      return { ...obj }
    },
    submitBefore() {
      for (let key in this.send) {
        if (!this.send[key]) {
          return this.$message({ showClose: true, message: '请完善寄件人信息', type: 'error', duration: 2000 })
        }
      }
      for (let key in this.receive) {
        if (!this.receive[key] && key !== 'addressInfo') {
          return this.$message({ showClose: true, message: '请完善收件人信息', type: 'error', duration: 2000 })
        }
      }
      if (!this.common.description) {
        return this.$message({ showClose: true, message: '请填写物品名称', type: 'error', duration: 2000 })
      }
      let receive = this.deleteProperty(JSON.parse(JSON.stringify(this.receive)), ['addressInfo'])
      let reqData = {
        ...this.send,
        ...receive,
        inputMode: '1',
        description: this.common.description
      }
      if (this.common.remark) {
        reqData['remark'] = this.common.remark
      }
      if (this.common.otherAddress) {
        reqData['receiveAddress'] = this.receive.receiveAddress + this.common.otherAddress
      }
      this.submit(reqData)
    },
    submit(data) {
      this.submitLoading = true
      erpWaybillAdd(data)
        .then(res => {
          this.close()
          this.$emit('refresh')
          this.submitLoading = false
        })
        .catch(error => {
          this.submitLoading = false
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.section-box {
  overflow: hidden;
  margin: -30px -20px -50px;
  background: #f5f5f5;

  .mail-box {
    margin: 10px 20px;
    .mail-box-item {
      background: #fff;
      border-radius: 6px;
      width: calc((100% - 10px) / 2);
      .item-top {
        height: 40px;
        border-radius: 6px 6px 0 0;
        background: #fafafa;
        padding: 0 10px;
        .label {
          width: 22px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          border-radius: 50%;
          background: #000;
          color: #fff;
          margin-right: 10px;
        }
      }
      .item-form {
        padding: 15px 10px 0;
      }
    }
  }

  .common-box {
    background: #fff;
    margin: 0 20px 10px;
    border-radius: 6px;
    padding: 10px;
    .goods-logs {
      span {
        width: 70px;
        text-align: right;
        margin-right: 10px;
        i {
          color: red;
          margin-right: 5px;
        }
      }
    }
    .auto-jd {
      margin: 20px 0;
      div {
        border: 1px solid rgb(7, 124, 242);
        padding: 10px;
        border-radius: 4px;
      }
    }
  }

  .action-box {
    background: #fff;
    border-radius: 6px;
    margin: 0 20px 10px;
    padding: 10px;
    .el-button {
      width: 100px;
    }
  }
}
</style>

<style>
.mail-dialog .el-dialog__header {
  padding-top: 10px;
}
.mail-dialog .el-dialog__headerbtn {
  top: 15px;
}
.el-pagination .el-select .el-input .el-input__inner {
  padding-right: 25px;
  border-radius: 3px;
  border: none;
  background: #f9fafc;
}
.el-pagination__sizes {
  margin: 0 10px 0 0;
  font-weight: 400;
  color: #606266;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
